#r @"node_modules/fable-import/Fable.Import.dll"
open Fable.Core

type Json =
  abstract parse: string -> obj
  abstract stringify: obj -> string

type Console =
  abstract log: 't -> unit

type JQuery =
  abstract hide : unit -> unit
  abstract ready : (unit -> unit) -> unit
  abstract scrollTop : unit -> int

[<Erase>]
module Lib =
  let [<Global>] window: obj = failwith "JS only"
  let [<Global>] document: obj = failwith "JS only"
  let [<Global>] JSON: Json = failwith "JS only"
  let [<Global>] console: Console = failwith "JS only"
  [<Emit("jQuery($0)")>]
  [<Global>]
  let jQuery(_:'t) : JQuery = failwith "JS only"

let find i = Lib.jQuery i

Lib.console.log "Hello from Fable"

//Lib.jQuery("body").hide()
let document = find Lib.document
let window = find Lib.window
(*
function displayProgress() {
  var scroll = $(window).scrollTop();
  var max = $(document).height() - $(window).height();
  var percent = 100;
  if (max > 0)
    percent = 100 * scroll / max;
  $("#progressDisplay #progressText").text(Math.round(percent) + '%');
}
*)
let displayProgress () =
 let scroll = window.scrollTop()
 Lib.console.log(scroll)


document.ready(
  fun _ -> displayProgress ()
)
